import NextHead from "next/head";
import { string } from "prop-types";
import { GoogleFonts } from "next-google-fonts";

const defaultDescription =
  "The perfect place to find a co-founder that matches your skillset. Get started for free today and get your amazing idea off the ground!";
const defaultOGURL = "https://www.starthawk.io/";
const defaultOGImage = "https://www.starthawk.io/static/email/welcome.jpg";
const defaultTitle = "Starthawk - Find your co-founder";

const Head = (props) => (
  <>
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{props.title || defaultTitle}</title>
      <meta
        name="description"
        content={props.description || defaultDescription}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/static/favicon.ico" />
      <link rel="canonical" href={props.url} />
      <meta property="og:url" content={props.url || defaultOGURL} />
      <meta property="og:title" content={props.title || ""} />
      <meta
        property="og:description"
        content={props.description || defaultDescription}
      />
      {props.noIndex && <meta name="robots" content="noindex" />}
      <meta name="twitter:site" content={props.url || defaultOGURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.ogImage || defaultOGImage} />
      <meta property="og:image" content={props.ogImage || defaultOGImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: "StartHawk",
            url: "https://www.starthawk.io",
            potentialAction: {
              "@type": "SearchAction",
              target: "{search_term_string}",
              "query-input": "required name=search_term_string",
            },
          }),
        }}
      />
    </NextHead>
    <GoogleFonts href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" />
  </>
);

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string,
};

export default Head;
